



























































import { LanguageKey } from "@/store/modules/common/interfaces";
import {
  BOT_BACKGROUND,
  DIGITAL_HUMAN_SETTING
} from "@/store/modules/digital_human/constants";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
export default Vue.extend({
  name: "DigitalHumanHeader",
  computed: {
    LanguageKey() {
      return LanguageKey;
    },
    ...mapGetters("digital_human", {
      bot_background: BOT_BACKGROUND,
      bot_setting: DIGITAL_HUMAN_SETTING
    }),
    botBackground: {
      get() {
        return this.bot_background;
      },
      set(newValue) {
        this.set_bot_background(newValue);
      }
    }
  },
  methods: {
    ...mapMutations("digital_human", {
      set_bot_background: BOT_BACKGROUND
    })
  }
});
