import { render, staticRenderFns } from "./AIBot.vue?vue&type=template&id=7e733849&scoped=true&"
import script from "./AIBot.vue?vue&type=script&lang=ts&"
export * from "./AIBot.vue?vue&type=script&lang=ts&"
import style0 from "./AIBot.vue?vue&type=style&index=0&id=7e733849&lang=scss&scoped=true&"
import style1 from "./AIBot.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e733849",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VIcon,VSkeletonLoader,VTextarea,VTooltip})
