var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"data",class:{
    arabic_direction: _vm.bot_setting.language === _vm.LanguageKey.ARABIC,
    root: _vm.bot_setting.language === _vm.LanguageKey.ENGLISH
  }},[_c('div',{ref:"scroll",staticClass:"chat-content"},[(_vm.bot_content_loading)?_vm._l((4),function(index){return _c('div',{key:index},[_c('v-skeleton-loader',{staticClass:"v-skeleton-loader v-skeleton-loader--is-loading theme--light",attrs:{"type":"list-item-avatar"}}),_c('v-skeleton-loader',{staticClass:"v-skeleton-loader v-skeleton-loader--is-loading theme--light",attrs:{"type":"list-item"}})],1)}):_vm._l((_vm.interview_history),function(history,index){return _c('div',{key:index},[(_vm.total_questions < _vm.total && index === 0)?_c('div',{staticClass:"d-flex align-center justify-center pb-4"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","color":"primary"},on:{"click":_vm.load_more}},[_vm._v(" Load More... ")])],1):_vm._e(),(history.role === _vm.ChatBotRoles.BOT)?_c('div',{staticClass:"d-flex align-center my-5"},[_c('div',{staticClass:"box-bot box"},[_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(_vm._s(history.content))])])]):(history.role === _vm.ChatBotRoles.USER)?_c('div',[_c('div',{staticClass:"box-user box"},[_c('p',{staticClass:"mb-0 base-body-text",staticStyle:{"direction":"ltr"}},[_vm._v(" "+_vm._s(history.content)+" ")])])]):_vm._e()])})],2),_c('div',{staticClass:"form-field d-flex align-center flex-row my-8",staticStyle:{"gap":"0.5rem"}},[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"bot-btn",attrs:{"icon":"","plain":""},on:{"click":_vm.speak_config}},'v-btn',attrs,false),on),[_c('inline-svg',{attrs:{"src":require('@/assets/icons/bold/microphone.svg')}})],1)]}}])},[_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(_vm.is_microphone_active ? "Microphone Enabled" : "Microphone Disabled")+" ")])]),_c('v-textarea',{staticClass:"form-field-input text-white",attrs:{"rows":_vm.prompt ? 2 : 1,"elevation":"0","type":"text","color":"white","placeholder":"Type a reply here..","solo":"","dark":"","hide-details":"","clearable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.send_command.apply(null, arguments)}},model:{value:(_vm.prompt),callback:function ($$v) {_vm.prompt=$$v},expression:"prompt"}}),(_vm.prompt)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"bot-btn",staticStyle:{"z-index":"900"},attrs:{"icon":"","plain":""},on:{"click":_vm.cancel_prompt}},[_c('v-icon',[_vm._v(" mdi-close ")])],1):_vm._e(),_c('v-btn',{staticClass:"bot-btn",staticStyle:{"z-index":"900"},attrs:{"icon":"","plain":"","loading":_vm.loading},on:{"click":_vm.send_command}},[_c('inline-svg',{attrs:{"src":require('@/assets/icons/bold/send.svg')}})],1),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"bot-btn",staticStyle:{"z-index":"900"},attrs:{"icon":"","plain":"","loading":_vm.loading},on:{"click":function($event){return _vm.$emit('stop')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close")])],1)]}}])},[_c('span',{staticClass:"black--text"},[_vm._v("Interrupt Bot")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }