









































































































































import Vue from "vue";
import {
  AnswerPayload,
  ChatBotRoles,
  FetchAssessmentAnswersApiPayload,
  FetchAssessmentQuestionnariesPayload,
  QuestionnairePayload
} from "@/store/modules/candidates/interfaces";
import { mapActions, mapGetters } from "vuex";
import {
  APP_ASSESSMENT_ANSWERS,
  APP_ASSESSMENT_QUESTIONNAIRES,
  HR_ASSISTANT_ASK_V2
} from "@/store/modules/candidates/constants";
import { IApp } from "@/interfaces";
import { LanguageKey } from "@/store/modules/common/interfaces";
import {
  DIGITAL_HUMAN_SETTING,
  DIGITAL_HUMAN_LOADING
} from "@/store/modules/digital_human/constants";
import { APP_ASSESSMENTS } from "@/store/modules/common/constants";
import { Assessment, List } from "@/interfaces/app_assessments";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import { wait_until } from "@/utils/global";
import { RESET_ASSESSMENT_HISTORY } from "@/store/modules/recruiter/constants";
export default Vue.extend({
  name: "AIBot",
  data: () => ({
    prompt: "",
    transcription: "",
    loading: false,
    interview_history: [] as Array<{
      role: ChatBotRoles;
      content: string;
      picture?: string;
    }>,
    recognition: null as typeof window.webkitSpeechRecognition | null,
    is_microphone_active: false,
    generated_voices: new Set<string>(),
    assessment_id: null as number | null,
    total: 0 as number, // Total count
    total_questions: 0 as number, // Total questions
    page: 1 as number, // Page
    more_question_extract: false as boolean, // Is more question extract
    bot_content_loading: false as boolean, // Bot content loading
    delete_con: 1 as number
  }),
  props: {
    in_session: {
      type: Boolean
    }
  },
  watch: {
    in_session() {
      if (!this.in_session) {
        this.delete_con = 1;
      }
    }
  },
  computed: {
    ...mapGetters("digital_human", {
      bot_setting: DIGITAL_HUMAN_SETTING,
      bot_loading: DIGITAL_HUMAN_LOADING
    }),
    ...mapGetters("common", {
      get_app_assessments: APP_ASSESSMENTS
    }),
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    }),
    LanguageKey() {
      return LanguageKey;
    },
    ChatBotRoles() {
      return ChatBotRoles;
    }
  },
  async mounted() {
    this.init_speak_recognition();

    const response = this.get_app_assessments;
    if (response) {
      const chat_bot = response.find((assessment: Assessment) => {
        return assessment.assessment_type === List.CHAT_BOT_MESSAGES;
      });
      if (chat_bot) {
        this.assessment_id = chat_bot.id;
        this.init_history();
      } else {
        this.interview_history.push({
          role: ChatBotRoles.BOT,
          content:
            "Apologies, the chat bot is currently unavailable. Please try again later."
        });
      }
    } // Get chat bot
    else {
      this.interview_history.push({
        role: ChatBotRoles.BOT,
        content:
          "Apologies, the chat bot is currently unavailable. Please try again later."
      });
    }
  },
  methods: {
    ...mapActions("candidate", {
      hr_assisitant_v2: HR_ASSISTANT_ASK_V2,
      get_assessment_question: APP_ASSESSMENT_QUESTIONNAIRES,
      get_assessment_answer: APP_ASSESSMENT_ANSWERS
    }),
    ...mapActions("recruiter", {
      reset_assessment_chat_history: RESET_ASSESSMENT_HISTORY
    }),
    async send_command() {
      // Chat with bot
      if (this.prompt.length) {
        this.loading = true; // loading for question
        if (this.is_microphone_active) {
          this.speak_config();
        }
        // Push user prompt
        this.interview_history.push({
          role: ChatBotRoles.USER,
          content: this.prompt
        });
        // Get bot answer
        const prompt_message = this.prompt;
        this.prompt = "";
        const result = await this.hr_assisitant_v2({
          question: prompt_message,
          portal: process.env.VUE_APP_HR_PORTAL ?? IApp.Portals.DIGITAL_HUMAN,
          conversational: true,
          delete_con: this.delete_con
        });
        this.delete_con = 0;
        // Push bot answer
        if (result) {
          this.loading = false;
          this.interview_history.push({
            role: ChatBotRoles.BOT,
            content: result.answer
          });
          this.$emit("bot_ans", result.answer);
        } else {
          this.loading = false;
          // Push bot answer if not found
          this.interview_history.push({
            role: ChatBotRoles.BOT,
            content: "Sorry, Failed to get response"
          });
        }
      }
    },
    speak_config() {
      this.is_microphone_active = !this.is_microphone_active;
      if (this.is_microphone_active) {
        this.recognition.start();
      } else {
        this.recognition.stop();
      }
    },
    init_speak_recognition() {
      const speech_recognition = window.webkitSpeechRecognition;
      this.recognition = new speech_recognition(); // Create a new speech recognition object
      this.recognition.interimResults = true; // Set interim results to true so that we can get the result before the user stops speaking
      this.recognition.continuous = true; // Set continuous to true so that we can get the result continuously
      this.recognition.onresult = (event: any) => {
        const results = Array.from(
          // eslint-disable-next-line no-undef
          event.results as SpeechRecognitionResult[]
        );
        let last_final_result = null;
        for (let i = results.length - 1; i >= 0; i--) {
          // Iterate in reverse
          if (results[i].isFinal) {
            last_final_result = results[i];
            break; // Found it, no need to continue
          }
        }

        if (last_final_result) {
          const transcript = last_final_result[0].transcript;
          if (transcript) {
            const formatted = transcript.trim().toLowerCase();
            if (!this.generated_voices.has(formatted)) {
              this.prompt += " " + formatted;
              this.generated_voices.add(formatted);
            }
          }
        }
      };
    },
    cancel_prompt() {
      this.prompt = "";
      this.generated_voices.clear();
      if (this.is_microphone_active) this.speak_config();
      this.recognition.stop();
    },
    async init_history() {
      // Fetch assessment question
      this.bot_content_loading = true; // loading for bot content
      if (this.assessment_id) {
        if (this.bot_setting.reset_chat_history) {
          await this.reset_assessment_chat_history({
            assessment_id: this.assessment_id,
            user_id: this.get_user_details.id,
            chat_bot: true
          });
          this.bot_content_loading = false; // loading for bot content
          return;
        }
        const payload: FetchAssessmentQuestionnariesPayload = {
          assessment_id: this.assessment_id,
          page: this.page - 1,
          limit: 10,
          user_id: this.get_user_details.id,
          order: "desc"
        };
        const question = await this.get_assessment_question(payload);
        // If History found
        if (question?.results) {
          const question_ids = question.results.map(
            (question: QuestionnairePayload) => question.id
          );
          this.total = question.total;
          this.total_questions += question.results.length;
          // Fetch assessment answer
          const answer: FetchAssessmentAnswersApiPayload = {
            assessment_id: this.assessment_id,
            page: 0,
            limit: 10,
            user_id: this.get_user_details.id,
            question_ids: question_ids
          };
          const answers = await this.get_assessment_answer(answer);
          if (answers?.results) {
            // Reverse the array for the first time to show the latest question
            question.results = !this.more_question_extract
              ? question.results.reverse()
              : question.results;
            answers.results = !this.more_question_extract
              ? answers.results.reverse()
              : answers.results;

            question.results.map((question: QuestionnairePayload) => {
              const matched_result = answers.results.find(
                (answer: AnswerPayload) => answer.question_id === question.id
              );

              if (matched_result) {
                if (this.more_question_extract) {
                  // If extracting user by clicking load_more to add on top
                  this.interview_history.unshift({
                    role: ChatBotRoles.BOT,
                    content: matched_result.answer
                  });
                  this.interview_history.unshift({
                    role: ChatBotRoles.USER,
                    content: question.question_text
                  });
                } else {
                  this.interview_history.push({
                    role: ChatBotRoles.USER,
                    content: question.question_text,
                    picture: ""
                  });
                  this.interview_history.push({
                    role: ChatBotRoles.BOT,
                    content: matched_result.answer
                  });
                }
              }
            });
          }
          if (this.interview_history.length === 0) {
            this.interview_history.push({
              role: ChatBotRoles.BOT,
              content: this.bot_setting.welcome_message
            });
          }
          this.bot_content_loading = false; // loading for bot content
          if (!this.more_question_extract) this.scroll_to_bottom(); // Scroll to bottom
          this.more_question_extract = true;
        }
      }
    },
    async scroll_to_bottom() {
      // Wait for the refs
      await wait_until(100);
      // Scroll to bottom
      const scroll = this.$refs.scroll as HTMLElement;
      if (scroll) scroll.scrollTop = scroll.scrollHeight;
    },
    load_more() {
      this.bot_content_loading = true;
      this.page++;
      this.init_history();
    }
  },
  beforeDestroy() {
    if (this.recognition) {
      if (this.is_microphone_active) this.speak_config();
      this.recognition.stop();
    }
  }
});
