





































import { LanguageKey } from "@/store/modules/common/interfaces";
import {
  DIGITAL_HUMAN_LOADING,
  DIGITAL_HUMAN_SETTING
} from "@/store/modules/digital_human/constants";
import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
  name: "IntroPopup",
  computed: {
    LanguageKey() {
      return LanguageKey;
    },
    ...mapGetters("digital_human", {
      bot_setting: DIGITAL_HUMAN_SETTING,
      bot_loading: DIGITAL_HUMAN_LOADING
    })
  }
});
