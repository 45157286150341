
































import Vue from "vue";

export default Vue.extend({
  name: "AppResourseNotFound",
  props: {
    title: {
      type: String
    },
    back_process: {
      type: Boolean,
      default: true
    },
    hide_button: {
      type: Boolean,
      default: false
    }
  }
});
