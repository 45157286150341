import { io } from "socket.io-client";
import store from "@/store";
import { NOTIFICATIONS } from "@/store/modules/notifications/constants";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL ?? "http://localhost:5000";
const socket = io(BACKEND_URL, {
  query: {
    user_id: store.getters[`auth/${GET_USER_DETAILS}`]?.id ?? null
  },
  transports: ["websocket"]
});

socket.on("connect", () => {
  // console.log("Socket connected");
});

socket.on("chat message", (value) => {
  // console.log("Value from server", value);
  store.commit(`notifications/${NOTIFICATIONS}`, value);
});

export default {
  socket
};
