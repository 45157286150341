

















































































import Vue from "vue";
import AIBot from "@/components/hygen/AIBot.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import { LOGOUT_USER } from "@/store/modules/auth/constants";
import DigitalHumanHeader from "@/components/digital_human/Header.vue";
import {
  BOT_BACKGROUND,
  DIGITAL_HUMAN_LOADING,
  DIGITAL_HUMAN_SETTING,
  HANDLE_HYGEN_ICE,
  HYGEN_PEER_CONNECTION,
  HYGEN_RENDER_ID,
  HYGEN_SESSION_ERROR,
  HYGEN_SESSION_ID,
  HYGEN_SESSION_INFO,
  INIT_DIGITAL_HUMAN,
  INIT_HYGEN_SESSION,
  INTERRUPT_HYGEN_SESSION,
  RESET_HYGEN_STORE,
  SPEAK_HYGEN_BOT,
  START_HYGEN_SESSION,
  STOP_HYGEN_SESSION
} from "@/store/modules/digital_human/constants";
import IntroPopup from "@/components/digital_human/IntroPopup.vue";
import DigitalHumanErrorPopup from "@/components/digital_human/DigitalHumanErrorPopup.vue";
import {
  InitHygenSessionPayload,
  StartHygenSessionPayload
} from "@/store/modules/digital_human/interfaces";
import { LanguageKey } from "@/store/modules/common/interfaces";
import { SocketEvents } from "@/interfaces/app.interface";
import socketService from "@/services/socket.service";

export default Vue.extend({
  name: "DigitalHumanV2",
  components: {
    AIBot,
    DigitalHumanHeader,
    IntroPopup,
    DigitalHumanErrorPopup
  },
  data() {
    return {
      bot_answer: "",
      settingMenu: false,
      captions: false,
      arabic: false,
      countdown: 0,
      emit_interval: 0,
      countdown_interval: 0,
      is_user_consumption: false,
      in_session: true
    };
  },
  computed: {
    LanguageKey() {
      return LanguageKey;
    },
    ...mapGetters("digital_human", {
      bot_background: BOT_BACKGROUND,
      bot_setting: DIGITAL_HUMAN_SETTING,
      get_session_id: HYGEN_SESSION_ID,
      get_session_info: HYGEN_SESSION_INFO,
      get_peer_connection: HYGEN_PEER_CONNECTION,
      get_render_id: HYGEN_RENDER_ID,
      get_hygen_session_error: HYGEN_SESSION_ERROR
    })
  },
  watch: {
    bot_background() {
      this.change_background();
    }
  },
  async created() {
    if (!this.bot_setting?.intro_popup) this.start_chat();
    else await this.init_digital_human();
    if (sessionStorage.getItem("session_user_count")) {
      socketService.socket.emit(SocketEvents.DIGITAL_HUMAN_CONSUMPTION, {
        time: parseInt(`${sessionStorage.getItem("session_user_count")}`)
      });
    }
    if (sessionStorage.getItem("session_remove")) {
      this.is_user_consumption = true;
      this.stop_hygen(sessionStorage.getItem("session_remove"));
    }
  },
  mounted() {
    const mediaElement = this.$refs.mediaElement as HTMLVideoElement;
    if (mediaElement) {
      mediaElement.onloadedmetadata = () => {
        mediaElement.play();
      };
    }
  },
  methods: {
    ...mapActions("digital_human", {
      init_digital_human: INIT_DIGITAL_HUMAN,
      init_hygen_session: INIT_HYGEN_SESSION,
      handle_ice: HANDLE_HYGEN_ICE,
      start_hygen_session: START_HYGEN_SESSION,
      stop_hygen_session: STOP_HYGEN_SESSION,
      speak_hygen_bot: SPEAK_HYGEN_BOT,
      interrupt_hygen_bot: INTERRUPT_HYGEN_SESSION
    }),
    ...mapMutations("digital_human", {
      set_bot_background: BOT_BACKGROUND,
      set_session_id: HYGEN_SESSION_ID,
      set_session_info: HYGEN_SESSION_INFO,
      set_peer_connection: HYGEN_PEER_CONNECTION,
      set_render_id: HYGEN_RENDER_ID,
      set_digital_human_loading: DIGITAL_HUMAN_LOADING,
      set_hygen_session_error: HYGEN_SESSION_ERROR,
      reset_hygen_store: RESET_HYGEN_STORE
    }),
    ...mapActions("auth", {
      logout_user: LOGOUT_USER
    }),
    ...mapMutations({
      root_error: ROOT_ERROR
    }),
    async init(loading = true) {
      this.is_user_consumption = false;
      if (loading) this.set_digital_human_loading(true);
      // Hide canvas and show video element, if canvas is not hidden
      const canvasElement = this.$refs.canvasElement as HTMLCanvasElement;
      const mediaElement = this.$refs.mediaElement as HTMLVideoElement;
      if (mediaElement.classList.contains("hide")) {
        this.set_bot_background(false);
        this.hideElement(canvasElement);
        this.showElement(mediaElement);
      }
      const init_hygen_session_payload: InitHygenSessionPayload = {
        avatar_name: this.bot_setting.bot_avatar,
        voice: {
          voice_id: this.bot_setting.bot_voice_id
        },
        quality: "high"
      }; // Payload to initialize Hygen session
      const sessionInfo = await this.init_hygen_session(
        init_hygen_session_payload
      ); // Initialize Hygen session
      if (!sessionInfo || !sessionInfo?.session_id) return;

      const {
        session_id,
        sdp: serverSdp,
        ice_servers2: iceServers
      } = sessionInfo;
      this.set_session_id(session_id);
      this.set_session_info(sessionInfo);

      const peerConnection = new RTCPeerConnection({
        iceServers: iceServers
      });
      this.set_peer_connection(peerConnection);
      this.get_peer_connection.ontrack = (event: any) => {
        const mediaElement = this.$refs.mediaElement as HTMLVideoElement;
        if (
          (event.track.kind === "audio" || event.track.kind === "video") &&
          mediaElement
        ) {
          mediaElement.srcObject = event.streams[0];
        }
      };
      this.get_peer_connection.onconnectionstatechange = (event: any) => {
        if (this.get_peer_connection?.connectionState === "disconnected") {
          this.init(false);
        }

        if (this.get_peer_connection?.connectionState === "connected") {
          this.change_background();
          // this.render_canvas();
          if (loading && this.bot_setting.welcome_message) {
            this.bot_ans(this.bot_setting.welcome_message);
          }
        }
      };
      // Set server's SDP as remote description
      const remoteDescription = new RTCSessionDescription(serverSdp);
      await this.get_peer_connection.setRemoteDescription(remoteDescription);
      await this.initStartSession();
      this.set_digital_human_loading(false);
    },
    async initStartSession() {
      if (!this.get_session_id || !this.get_peer_connection) {
        this.set_hygen_session_error("Failed to start session");
        return;
      }
      // Create and set local SDP description
      const localDescription = await this.get_peer_connection.createAnswer();
      await this.get_peer_connection.setLocalDescription(localDescription);
      // When ICE candidate is available, send to the server
      this.get_peer_connection.onicecandidate = async ({
        candidate
      }: {
        candidate: any;
      }) => {
        // console.log("Received ICE candidate:", candidate);
        if (candidate) {
          await this.handle_ice({
            session_id: this.get_session_id,
            candidate
          });
        }
      };

      const start_hygen_session: StartHygenSessionPayload = {
        session_id: this.get_session_id,
        sdp: localDescription
      };
      await this.start_hygen_session(start_hygen_session);
      const videoELement = this.$refs.mediaElement as HTMLVideoElement;
      if (videoELement) {
        videoELement.style.maxHeight = window.innerHeight + "px";
        videoELement.style.maxWidth = "100%";
      }
    },
    hideElement(element: HTMLElement) {
      element.classList.add("hide");
      element.classList.remove("show");
    },
    showElement(element: HTMLElement) {
      element.classList.add("show");
      element.classList.remove("hide");
    },
    renderCanvas() {
      const canvasElement = this.$refs.canvasElement as HTMLCanvasElement;
      const mediaElement = this.$refs.mediaElement as HTMLVideoElement;

      const curRenderID = Math.trunc(Math.random() * 1000000000);
      this.set_render_id(curRenderID);
      const renderID = this.get_render_id;
      const ctx = canvasElement.getContext("2d", {
        willReadFrequently: true
      }) as any;

      function processFrame() {
        if (curRenderID !== renderID) return;
        if (!ctx) return;

        canvasElement.width = mediaElement.videoWidth;
        canvasElement.height = mediaElement.videoHeight;

        canvasElement.style.maxHeight = window.innerHeight + "px";
        canvasElement.style.maxWidth = "100%";
        ctx.drawImage(
          mediaElement,
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
        (ctx as any).getContextAttributes().willReadFrequently = true;
        const imageData = ctx.getImageData(
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
          const red = data[i];
          const green = data[i + 1];
          const blue = data[i + 2];

          // You can implement your own logic here
          if (green > 90 && red < 90 && blue < 90) {
            // if (isCloseToGray([red, green, blue])) {
            data[i + 3] = 0;
          }
        }

        ctx.putImageData(imageData, 0, 0);

        requestAnimationFrame(processFrame);
      }
      processFrame();
      this.hideElement(mediaElement);
      this.showElement(canvasElement);
      this.set_bot_background(true);
    },
    isCloseToGreen(color: number[]) {
      const [red, green, blue] = color;
      return green > 90 && red < 90 && blue < 90;
    },
    async bot_ans(ans: string) {
      this.bot_answer = ans;
      const chunks = this.split_text(this.bot_answer);
      for (const part of chunks) {
        await this.speak_hygen_bot({
          session_id: this.get_session_id,
          text: part
        });
      }
    },
    async start_chat() {
      this.is_user_consumption = false;
      window.addEventListener("beforeunload", this.before_unload, {
        passive: true
      });
      await this.init_digital_human();
      await this.init();
      await this.handle_socket_event();
      this.bot_setting.intro_popup = undefined;
    },
    async _logout_user() {
      this.is_user_consumption = true;
      await this.close_session();
      await this.logout_user();
      await this.$router.push("/auth/login");
    },
    change_background() {
      if (this.bot_background) {
        try {
          this.renderCanvas();
        } catch (e) {
          console.log("=== Error ===");
          console.error(e);
        }
      } else {
        const canvasElement = this.$refs.canvasElement as HTMLCanvasElement;
        const mediaElement = this.$refs.mediaElement as HTMLVideoElement;
        this.hideElement(canvasElement);
        this.showElement(mediaElement);
        this.set_render_id(this.get_render_id + 1);
      }
    },
    async refresh_process() {
      await this.close_session();
    },
    async close_session() {
      this.in_session = false;
      if (this.get_session_id) {
        await this.stop_hygen_session({ session_id: this.get_session_id });
      }
      window.removeEventListener("beforeunload", this.before_unload);
      sessionStorage.removeItem("session_remove");
      sessionStorage.removeItem("session_user_count");
      this.is_user_consumption = true;
      this.clear_interval();
      this.reset_hygen_store();
      this.init_digital_human();
    },
    split_text(text: string): string[] {
      const max_length = 950;
      const parts: string[] = [];

      // If text length is less than or equal to max_length, return the text as is
      if (text.length <= max_length) {
        return [text];
      }

      let start = 0;
      while (start < text.length) {
        // Find the end of the current chunk without breaking words
        let end = start + max_length;
        while (end < text.length && !/\s/.test(text.charAt(end))) {
          end++;
        }

        // Add the chunk to parts array
        parts.push(text.substring(start, end));

        // Update start for the next chunk
        start = end + 1;
      }

      return parts;
    },
    async before_unload(event: BeforeUnloadEvent) {
      event.returnValue = "Are you sure you want to leave?";
      sessionStorage.setItem("session_remove", this.get_session_id);
      sessionStorage.setItem("session_user_count", this.countdown.toString());
    },
    async stop_hygen(session_id: string | null) {
      if (session_id || this.get_session_id) {
        await this.stop_hygen_session({
          session_id: session_id || this.get_session_id
        });
      }
      if (!this.is_user_consumption) {
        this.clear_interval();
      }
      sessionStorage.removeItem("session_remove");
      sessionStorage.removeItem("session_user_count");
      window.removeEventListener("beforeunload", this.before_unload);
    },
    async handle_socket_event() {
      this.emit_interval = setInterval(() => {
        socketService.socket.emit(SocketEvents.DIGITAL_HUMAN_CONSUMPTION, {
          time: 30000
        });
      }, 30000);

      // Countdown timer
      this.countdown_interval = setInterval(() => {
        this.countdown += 1;
        if (this.countdown >= 30000) {
          this.countdown = 0;
        }
      }, 1);
    },
    clear_interval() {
      if (this.countdown <= 30000 && this.countdown > 0) {
        socketService.socket.emit(SocketEvents.DIGITAL_HUMAN_CONSUMPTION, {
          time: this.countdown
        });
      }
      this.countdown = 0;
      clearInterval(this.emit_interval);
      clearInterval(this.countdown_interval);
    },
    async interrupt_hygen() {
      await this.interrupt_hygen_bot({ session_id: this.get_session_id });
    }
  },
  beforeDestroy() {
    this.stop_hygen(this.get_session_id);
  }
});
